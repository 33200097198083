var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "product-categories"
  }, [_vm.mainCategory ? _c('div', {
    staticClass: "main"
  }, [_vm.popupCategories.length ? _c('a', {
    staticClass: "more-button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.showPopup = true;
      }
    }
  }, [_vm._v("+ " + _vm._s(_vm.popupCategories.length))]) : _vm._e(), _c('category-path', {
    attrs: {
      "path": _vm.mainCategory.c_id_path,
      "categories": _vm.baseCategories,
      "route-params": _vm.routeParams,
      "route-query": _vm.routeQuery,
      "target": "_blank"
    }
  })], 1) : _c('div', {
    staticClass: "empty"
  }, [_vm._v("—")]), _vm.popupCategories.length ? _c('fade-transition', [_vm.showPopup ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.onClickOutside,
      expression: "onClickOutside"
    }],
    staticClass: "popup"
  }, [_c('vue-custom-scrollbar', {
    staticClass: "scroll",
    attrs: {
      "settings": _vm.scrollSettings
    }
  }, [_c('table', [_c('thead', [_c('th', [_vm._v("Категория")]), _c('th', [_vm._v("Позиция")])]), _c('tbody', _vm._l(_vm.popupCategories, function (productCat, i) {
    return _c('tr', {
      key: i
    }, [_c('td', [_c('category-path', {
      attrs: {
        "path": productCat.c_id_path,
        "categories": _vm.baseCategories,
        "route-params": _vm.routeParams,
        "route-query": _vm.routeQuery,
        "target": "_blank"
      }
    })], 1), _c('td', [_vm._v(_vm._s(productCat.position))])]);
  }), 0)])])], 1) : _vm._e()]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };